import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

export const adminLedgerReport = createEncryptedAsyncThunk(
  "adminReportSlice/adminLedgerReport",
  "Wallet/GetLedgerDetail",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value
        ? Number(params.selectRetailer.value)
        : params.selectRetailer.value,
      service_Id: 0,
      transaction_type: "",
      transactionId: params.transactionId || "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);
export const adminBillPaymentReport = createEncryptedAsyncThunk(
  "adminReportSlice/adminBillPaymentReport",
  "account/GetBBPSReport",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userid: params.selectRetailer.value,
      txn_id: "",
      txn_type: "",
      txn_mode: "",
      customerNo: "",
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const adminLedgerReconsileReport = createEncryptedAsyncThunk(
  "adminReportSlice/adminLedgerReconsileReport",
  "Wallet/GetLedgerDetailsReconsile",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: Number(params.selectRetailer.value),
      service_Id: 0,
      transaction_type: "",
      transactionId: "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const checkBankStatusDmt = createEncryptedAsyncThunk(
  "adminReportSlice/checkBankStatus",
  "account/DMT_TransactionStatusCheckAPIDirect",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      merchantCode: params.data.merchantCode,
      clientId: params.data.clientID,
      clientSecretKey: params.data.clientSecretKey,
      transactionId: params.data.transaction_Id,
      updateTransactionFlag: true,
    }),
  }
);

export const getDailyPayinPayoutReport = createEncryptedAsyncThunk(
  "adminReportSlice/getDailyPayinPayoutReport",
  "account/GetDailyPayout_PayinReport",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      reportUserId: params.selectDistributor.value,
      fromDate: params.fromDate,
      toDate: params.toDate,
    }),
  }
);

export const adminselfLedgerReport = createEncryptedAsyncThunk(
  "adminReportSlice/adminselfLedgerReport",
  "Wallet/GetLedgerDetail",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.currentUser.userId,
      service_Id: 0,
      transaction_type: "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const getRetailerList = createEncryptedAsyncThunk(
  "adminReportSlice/adminBillPaymentReport",
  (params) =>
    `/Reports/UserLedgerReport?MobileNo=${params.selectRetailer.mobile}&FromDate=${params.fromDate}&ToDate=${params.toDate}&Role=${params.Role}`,
  {
    method: "GET",
  }
);
export const getAdminMoneyTransactionHistoryHold = createEncryptedAsyncThunk(
  "adminReportSlice/getAdminMoneyTransactionHistoryHold",
  "account/GetDMTTransaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      txn_id: "",
      dmt_transaction_userid: params.selectDistributor.value,
      startDate: params.fromDate ? params.fromDate : "",
      endDate: params.toDate ? params.toDate : "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
    }),
  }
);
export const getAdminNewMoneyTransactionHistoryHold = createEncryptedAsyncThunk(
  "adminReportSlice/getAdminNewMoneyTransactionHistoryHold",
  "account/GetDMTTransaction",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      txn_id: "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
      dmt_transaction_userid: Number(params.selectDistributor.value) || 0,
      startDate: params.fromDate ? params.fromDate : "",
      endDate: params.toDate ? params.toDate : "",
    }),
  }
);
export const adminLedgerReportPage = createEncryptedAsyncThunk(
  "adminReportSlice/adminLedgerReportPage",
  "account/GetLedgerDetailsPage",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value
        ? Number(params.selectRetailer.value)
        : params.selectRetailer.value,
      service_Id: 0,
      transaction_type: params.transactionType || "",
      transactionId: params.transactionId || "",
      status: Array.isArray(params.status)
        ? params.status
        : [Number(params.status)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
      pageSize: params.pageSize || 0,
      pageNumber: params.pageNumber || 0,
    }),
  }
);
export const getExcelLedgerDetail = createEncryptedAsyncThunk(
  "adminReportSlice/getExcelLedgerDetail",
  "account/GetExcelLedgerDetail",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value
        ? Number(params.selectRetailer.value)
        : params.selectRetailer.value,
      service_Id: 0,
      transaction_type: params.transaction_type || "",
      transactionId: params.transactionId || "",
      status: Array.isArray(params.status)
        ? params.status
        : [Number(params.status)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const checkBankStatus = createEncryptedAsyncThunk(
  "adminReportSlice/checkBankStatus",
  "account/DMT_TransactionStatusCheckAPIDirect",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      merchantCode: params.data.merchantCode,
      clientId: params.data.clientId,
      clientSecretKey: params.data.clientSecretKey,
      transactionId: params.data.txn_id,
      updateTransactionFlag: true,
    }),
  }
);

export const getCommissionReport = createEncryptedAsyncThunk(
  "adminReportSlice/getCommissionReport",
  "Service/GetCommissionReport",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const ReverseTrasactionAction = createEncryptedAsyncThunk(
  "adminReportSlice/ReverseTrasaction",
  "account/ReverseTrasaction",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      transactionId: params.transaction_Id,
      userID: params.currentUser.userGuid,
      service_Type: params.service_Type || "",
      service_Id: params.service_Id || 0,
      walletType: "1",
    }),
  }
);

export const AdminAEPSTransactionReports = createEncryptedAsyncThunk(
  "reportSlice/AdminAEPSTransactionReports",
  "account/GetAEPSReport",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      report_userid: params.selectRetailer
        ? params.selectRetailer.value
        : params.selectRetailer.value,
      txn_id: "",
      apiId: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);
export const adminGetAPIRequestResponseLog = createEncryptedAsyncThunk(
  'adminReportSlice/adminGetAPIRequestResponseLog',
  'Service/GetAPIRequestResponseLog',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      reference_Id: params.referenceId || '',
      service_Id: params.serviceId || 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const confirmSuccessManually = createEncryptedAsyncThunk(
  "adminReportSlice/confirmSuccessManually",
  "Transaction/UpdateTransStatus",
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 1,
      userID: 85,
    }),
  }
);
export const confirmFailedManually = createEncryptedAsyncThunk(
  "adminReportSlice/confirmFailedManually",
  "Transaction/UpdateTransStatus",
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 3,
      userID: 85,
    }),
  }
);

export const businessAnalysisReport = createEncryptedAsyncThunk(
  "reportSlice/businessAnalysisReport",
  "account/GetBalAnalysReport",
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      endDate: params.toDate,
    }),
  }
);

export const kycPayementReports = createEncryptedAsyncThunk(
  "adminReportSlice/confirmFailedManually",
  "account/CFPGGetAllPG",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid: params.currentUser.userId,
      transactionid: "",
      startDate: params.fromDate,
      endDate: params.toDate,
      status: [0, 1, 2],
    }),
  }
);

export const getPGTransactionReport = createEncryptedAsyncThunk(
  "adminReportSlice/getPGTransactionReport",
  "account/CFPGGetAllPG",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid: params.currentUser.userId,
      transactionid: "",
      startDate: params.fromDate,
      endDate: params.toDate,
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
    }),
  }
);

export const getTopUpTransectionHistory = createEncryptedAsyncThunk(
  "reportSlice/getTopUpTransectionHistory",
  "account/GetTopUpReport",
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      // reportUserId: Number(params.selectRetailer.value),
      reportUserId: params.selectRetailer
        ? Number(params.selectRetailer.value)
        : 0,

      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const adminReportSlice = createSlice({
  name: "adminReportSlice",
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
} = adminReportSlice.actions;
export default adminReportSlice.reducer;
